import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { Button, Icon } from '../../../atoms';
import style from './StageElements.module.scss';

type FollowUpAppointmentBookedProps = {
  appointmentInformation: any;
};

const cx = classNames.bind(style);

const circleWithIconClasses = cx({ CircleWithIcon: true });
const calendarDayContainerClasses = cx({ CalendarDayContainer: true });
const calendarDayNumberClasses = cx({ CalendarDayNumber: true });
const circleTextClasses = cx({ CircleText: true });
const descriptionSectionClasses = cx({ DescriptionSection: true });
const descriptionTitleClasses = cx({ DescriptionTitle: true });
const descriptionTextClasses = cx({ DescriptionText: true });
const descriptionTextSmallMarginClasses = cx({ DescriptionText: true, DescriptionTextSmallMargin: true });
const descriptionTextSemiBoldClasses = cx({ DescriptionText: true, DescriptionTextSemiBold: true });
const actionButtonsClasses = cx({ ActionButtons: true });

export const FollowUpAppointmentBooked = (props: FollowUpAppointmentBookedProps) => {
  const { appointmentInformation } = props;
  const appointmentStartDate = new Date(appointmentInformation.startDate);

  // set locale for date-fns
  setDefaultOptions({ locale: de });

  const openMeetingLink = () => {
    // TODO: remove this block when eSprechstunde is not used anymore
    const isESprechstundeMeeting =
      appointmentInformation.esprechstundeCode !== undefined && appointmentInformation.esprechstundeCode !== '';
    if (isESprechstundeMeeting) {
      window.open(
        `https://esprechstunde.net/termin/${appointmentInformation.esprechstundeCode}`,
        '_blank',
        'noreferrer'
      );

      return;
    }

    window.open(appointmentInformation.zohoJoinLink, '_blank', 'noreferrer');
  };

  const startDate = new Date(appointmentInformation.startDate);
  const endDate = new Date(appointmentInformation.endDate);
  const differenceInMs = endDate.getTime() - startDate.getTime();
  const appointmentDuration = Math.round(differenceInMs / 60000);

  return (
    <>
      <div className={circleWithIconClasses}>
        <div className={calendarDayContainerClasses}>
          <Icon icon='calendar' size={70} color={'white'} />
          <div className={calendarDayNumberClasses}>{format(appointmentStartDate, 'd')}</div>
        </div>
        <div className={circleTextClasses}>
          {appointmentInformation.type === 2 && `Termin`}
          {appointmentInformation.type === 1 && `Videosprechstunde`}
          <br />
          gebucht
        </div>
      </div>
      <div className={descriptionSectionClasses}>
        <div className={descriptionTextSmallMarginClasses}>
          {appointmentInformation.type === 2 && `Ihr Folgetermin `}
          {appointmentInformation.type === 1 && `Ihre Videosprechstunde `}
          wurde gebucht und findet statt am
        </div>
        <div className={descriptionTitleClasses}>{format(appointmentStartDate, 'PPPp')}&nbsp;Uhr</div>
        <div className={descriptionTextSemiBoldClasses}>
          Arzt: {`${appointmentInformation.doctorTitle} ${appointmentInformation.doctorName}`}
          <br />
          Dauer: {appointmentDuration} Minuten
        </div>
        {/* show practice location only for offline appointments */}
        {appointmentInformation.type === 2 && (
          <div className={descriptionTextClasses}>
            <span className={descriptionTextSemiBoldClasses}>Therapiezentrum</span>
            <br />
            {appointmentInformation.practice.name}
            <br />
            {appointmentInformation.practice.address[0].street} {appointmentInformation.practice.address[0].houseNumber}
            <br />
            {appointmentInformation.practice.address[0].postcode} {appointmentInformation.practice.address[0].city}
          </div>
        )}
        <div className={descriptionTextClasses}>
          {appointmentInformation.type === 2 &&
            `Bitte erscheinen Sie 15 Minuten vor dem Termin in dem Therapiezentrum.`}
          {appointmentInformation.type === 1 &&
            `Bitte erscheinen Sie fünf Minuten vor dem Termin in der Videosprechstunde.`}
        </div>

        {appointmentInformation.type === 1 && (
          <>
            <div className={actionButtonsClasses}>
              <Button onClick={openMeetingLink}>Zur Videosprechstunde</Button>
            </div>

            {(appointmentInformation.esprechstundeCode === undefined ||
              appointmentInformation.esprechstundeCode === '') && (
              <Link
                className={style['back-to-dashboard-btn']}
                to='https://www.nowomed.com/de/videosprechstunde/'
                target='_blank'
                style={{ marginBottom: '1rem' }}
              >
                <div>Anleitung zur Videosprechstunde</div>
              </Link>
            )}
          </>
        )}
      </div>
    </>
  );
};
