import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectAuth, selectUserInformation } from '../../../store/reducers';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import { Header } from '../../organisms/Header/Header';
import { Availability } from './Steps/Availability';
import { GeneralSideEffects } from './Steps/GeneralSideEffects';
import { IntroPageFUP } from './Steps/IntroPageFUP';
import { MedicalNews } from './Steps/MedicalNews';
import { Pharmacy } from './Steps/Pharmacy';
import { PsychologicalSideEffects } from './Steps/PsychologicalSideEffects';
import { ReplacePreparation } from './Steps/ReplacePreparation';
import { SelfAssessment } from './Steps/SelfAssessment';
import { SendFollowUpPrescription } from './Steps/SendFollowUpPrescription';
import { SuccessPage } from './Steps/SuccessPage';

type FollowUpPresciptionProps = {
  currentPage: number;
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export function FollowUpPrescription({ currentPage, setCurrentPage }: FollowUpPresciptionProps) {
  const navigate = useNavigate();
  const authInfo = useAppSelector(selectAuth);
  const userInformation = useAppSelector(selectUserInformation);

  useEffect(() => {
    let gotoError = false;

    if (authInfo.isAuthenticated && userInformation.id !== '') {
      // If we have a prescription request without a status (not been processed by doctor)
      if (
        userInformation.followUpPrescriptionRequest &&
        userInformation.followUpPrescriptionRequest.some((item) => !('status' in item))
      ) {
        gotoError = true;
      }

      // check if patient has a medication
      if (userInformation.medication?.length < 1) {
        gotoError = true;
      }
    }

    if (gotoError) {
      navigate('/error', { state: { message: 'Sie können aktuell kein Folgerezept beantragen.' } });
    }
  }, [authInfo, userInformation]);

  if (!authInfo.isAuthenticated || userInformation.id === '') {
    return null;
  }

  let pageToRender;

  switch (currentPage) {
    case 1:
      pageToRender = <IntroPageFUP setCurrentPage={setCurrentPage} />;
      break;
    case 2:
      pageToRender = <GeneralSideEffects setCurrentPage={setCurrentPage} />;
      break;
    case 3:
      pageToRender = <PsychologicalSideEffects setCurrentPage={setCurrentPage} />;
      break;
    case 4:
      pageToRender = <SelfAssessment setCurrentPage={setCurrentPage} />;
      break;
    case 5:
      pageToRender = <MedicalNews setCurrentPage={setCurrentPage} />;
      break;
    case 6:
      pageToRender = <Pharmacy setCurrentPage={setCurrentPage} />;
      break;
    case 7:
      pageToRender = <Availability setCurrentPage={setCurrentPage} />;
      break;
    case 8:
      pageToRender = <ReplacePreparation setCurrentPage={setCurrentPage} />;
      break;
    case 9:
      pageToRender = <SendFollowUpPrescription setCurrentPage={setCurrentPage} />;
      break;
    case 10:
      pageToRender = <SuccessPage setCurrentPage={setCurrentPage} />;
      break;
    default:
      pageToRender = <></>;
  }

  return (
    <React.Fragment>
      <Header />
      {pageToRender}
    </React.Fragment>
  );
}
