import React from 'react';
import { Link } from 'react-router-dom';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { Button, Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

type FirstAppointmentBookedProps = {
  appointmentInformation: any;
};

export const FirstAppointmentBooked = (props: FirstAppointmentBookedProps) => {
  const { appointmentInformation } = props;
  const appointmentStartDate = new Date(appointmentInformation.startDate);

  // set locale for date-fns
  setDefaultOptions({ locale: de });

  const openMeetingLink = () => {
    // TODO: remove this block when eSprechstunde is not used anymore
    const isESprechstundeMeeting =
      appointmentInformation.esprechstundeCode !== undefined && appointmentInformation.esprechstundeCode !== '';
    if (isESprechstundeMeeting) {
      window.open(
        `https://esprechstunde.net/termin/${appointmentInformation.esprechstundeCode}`,
        '_blank',
        'noreferrer'
      );

      return;
    }

    window.open(appointmentInformation.zohoJoinLink, '_blank', 'noreferrer');
  };

  const startDate = new Date(appointmentInformation.startDate);
  const endDate = new Date(appointmentInformation.endDate);
  const differenceInMs = endDate.getTime() - startDate.getTime();
  const appointmentDuration = differenceInMs / 60000;

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <div className={classes.CalendarDayContainer}>
          <Icon icon='calendar' size={70} color={'white'} />
          <div className={classes.CalendarDayNumber}>{format(appointmentStartDate, 'd')}</div>
        </div>
        <div className={classes.CircleText}>
          Termin
          <br />
          Erstgespräch
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>{format(appointmentStartDate, 'PPPp')}&nbsp;Uhr</div>
        <div className={`${classes.DescriptionText} ${classes.DescriptionTextSemiBold}`}>
          Arzt: {`${appointmentInformation.doctorTitle} ${appointmentInformation.doctorName}`}
          <br />
          Dauer: {appointmentDuration} Minuten
        </div>
        {/* show practice location only for offline appointments */}
        {appointmentInformation.type === 2 && (
          <div className={classes.DescriptionText}>
            <span className={classes.DescriptionTextSemiBold}>Therapiezentrum</span>
            <br />
            {appointmentInformation.practice.name}
            <br />
            {appointmentInformation.practice.address[0].street} {appointmentInformation.practice.address[0].houseNumber}
            <br />
            {appointmentInformation.practice.address[0].postcode} {appointmentInformation.practice.address[0].city}
          </div>
        )}
        <div className={classes.DescriptionText}>
          <span className={classes.DescriptionTextSemiBold}>Bitte beachten Sie:</span>
          <br />
          Der Termin für Ihr Erstgespräch kann nur stattfinden, wenn Sie uns alle notwendigen Dokumente mindestens 24
          Stunden vor Ihrem Termin übermittelt haben. Details dazu finden Sie in der Bestätigungs-Mail Ihres Termins.
        </div>
        {/* show button only for online appointments */}
        {appointmentInformation.type === 1 && (
          <>
            <div className={classes.ActionButtons}>
              <Button onClick={openMeetingLink}>Zur Videosprechstunde</Button>
            </div>

            {(appointmentInformation.esprechstundeCode === undefined ||
              appointmentInformation.esprechstundeCode === '') && (
              <Link
                className={classes['back-to-dashboard-btn']}
                to='https://www.nowomed.com/de/videosprechstunde/'
                target='_blank'
                style={{ marginBottom: '1rem' }}
              >
                <div>Anleitung zur Videosprechstunde</div>
              </Link>
            )}
          </>
        )}
      </div>
    </>
  );
};
