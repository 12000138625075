import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalStyles } from '../../../helper/GlobalStyles';
import { mergeStyles, signOff } from '../../../helper/UtilityFunctions';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectAuth,
  selectUserInformation,
  selectWindowData,
  setAuth,
  setUserInformation,
} from '../../../store/reducers';
import { initialUserInformation } from '../../../store/reducers/initialState';
import type { Address } from '../../../types/ninox.types';
import { Button, Label, Loading, Modal } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import { styles as stylesFn } from './MyAccount.style';
import classes from './MyAccount.module.scss';

export function MyAccount() {
  const navigate = useNavigate();

  const { isDesktop } = useAppSelector(selectWindowData);

  const userInformation = useAppSelector(selectUserInformation);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<Address>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const getUserData = async () => {
    setLoading(true);
    if (userInformation.address !== undefined && userInformation.address.length > 0) {
      setAddress(userInformation?.address[0]);
    }
    setLoading(false);
  };

  const closeDeleteSuccess = () => {
    signOff({ auth, nav: () => navigate('/login') });

    const authRedux = {
      isAuthenticated: false,
      isTermsAgree: false,
      name: '',
      isPrivacyPolicyAgree: false,
    };
    dispatch(setUserInformation(initialUserInformation));
    dispatch(setAuth(authRedux));
    setDeleteSuccess(false);
  };

  const deleteAccount = async () => {
    const response = await UserService.deleteAccount();

    if (!response.error) {
      setDeleteModal(false);
      setDeleteSuccess(true);
      setDeleteError(false);
    }
    if (response.error) {
      setDeleteError(true);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    (async () => getUserData())();
  }, [userInformation]);

  if (loading) {
    return <Loading />;
  }

  const styles = stylesFn(isDesktop);

  return (
    <React.Fragment>
      <Header />
      <div className={classes.MyAccount} style={styles.scrollview}>
        <Modal
          modalVisible={deleteModal}
          setModalVisible={setDeleteModal}
          primaryButtonText='Ja, löschen'
          secondaryButtonText='Abbrechen'
          cb={deleteAccount}
          primaryButtonStyle={{ background: '#dc0000', border: `1px solid #dc0000` }}
        >
          <div className='text' style={styles.deleteModalTitle}>
            Sind Sie sich sicher?
          </div>
          <div className='text' style={styles.deleteModalText}>
            Möchten Sie Ihren Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.
          </div>
          <div className='text' style={styles.deleteModalText}>
            Bitte beachten Sie, dass die Löschung bis zu 48 Stunden dauern kann. Im Anschluss werden Sie per E-Mail
            benachrichtigt.
          </div>
        </Modal>
        <Modal
          modalVisible={deleteSuccess}
          setModalVisible={setDeleteSuccess}
          cb={closeDeleteSuccess}
          disableSecondaryButton={true}
          primaryButtonText='Schließen'
        >
          <div style={isDesktop ? styles.deleteModalWeb : styles.deleteModal}>
            <div className='text' style={styles.deleteModalTitle}>
              Ihr Account wird gelöscht
            </div>
            <div className='text' style={styles.deleteModalText}>
              Der Antrag zur Löschung Ihres Accounts ist bei uns eingegangen und wird schnellstmöglich bearbeitet.
              Sobald der Vorgang abgeschlossen ist, werden Sie per E-Mail informiert.
            </div>
          </div>
        </Modal>
        <Modal
          modalVisible={deleteError}
          setModalVisible={setDeleteError}
          disableSecondaryButton={true}
          cb={() => setDeleteError(false)}
          primaryButtonText='Schließen'
        >
          <div style={isDesktop ? styles.deleteModalWeb : styles.deleteModal}>
            <div className='text' style={styles.deleteModalTitle}>
              Es ist ein Problem aufgetreten
            </div>
            <div className='text' style={styles.deleteModalText}>
              Es ist ein Problem aufgetreten. Sollte dieses weiterhin bestehen, wenden Sie sich bitte an{' '}
              <a className='underlined black' href='mailto:support@nowomed.de'>
                support@nowomed.de
              </a>
            </div>
          </div>
        </Modal>
        <div className={classes.container} style={styles.container}>
          <div
            style={mergeStyles([
              globalStyles.row,
              styles.alignCenter,
              isDesktop ? globalStyles.marginBottom35 : globalStyles.marginBottom10,
            ])}
          >
            <div
              style={mergeStyles([
                styles.greenCircleContainer,
                globalStyles.marginRight10,
                !isDesktop ? { justifyContent: 'center' } : {},
              ])}
            >
              <div className='text' style={styles.greenCircleText}>
                {userInformation?.firstname.slice(0, 1)}
                {userInformation?.lastname.slice(0, 1)}
              </div>
            </div>
            <div
              style={mergeStyles([
                globalStyles.column,
                isDesktop ? globalStyles.marginLeft40 : globalStyles.marginLeft10,
              ])}
            >
              <div
                className='text'
                style={styles.heading2}
              >{`${userInformation?.firstname} ${userInformation?.lastname}`}</div>
              <div className='text'>{`${userInformation?.email}`}</div>
            </div>
          </div>

          <div
            style={mergeStyles([
              isDesktop ? globalStyles.row : globalStyles.column,
              isDesktop ? globalStyles.marginVertical35 : globalStyles.marginVertical15,
              isDesktop ? styles.justifyContentStart : {},
              isDesktop ? styles.alignItemsStart : {},
            ])}
          >
            <div className={classes['contact-field']} style={isDesktop ? {} : globalStyles.marginVertical25}>
              <Label style={[styles.label]}>Adresse</Label>
              <div className='text' style={styles.textBold}>{`${address?.street ?? '-'} ${
                address?.houseNumber ? `${address.houseNumber},` : ''
              } ${address?.postcode ?? ''} ${address?.city ?? ''}`}</div>
            </div>

            <div className={classes['contact-field']} style={isDesktop ? {} : globalStyles.marginVertical15}>
              <Label style={[styles.label]}>Telefon</Label>
              <div className='text' style={mergeStyles([styles.textBold, styles.label])}>
                Mobil: {userInformation?.mobile ? userInformation?.mobile : '/'}
              </div>
            </div>

            <div className={classes['contact-field']} style={isDesktop ? {} : globalStyles.marginVertical15}>
              <Label style={[styles.label]}>E-mail</Label>
              <div className='text' style={styles.textBold}>
                {userInformation?.email}
              </div>
            </div>
          </div>

          {userInformation?.progress.isDataConfirm === true && (
            <div
              className={classes['button-container']}
              style={mergeStyles([globalStyles.row, styles.marginRow, styles.buttonContainer])}
            >
              <Button
                style={styles.button}
                onClick={() => {
                  navigate('/update-personal-data');
                }}
              >
                <div className='text' style={styles.buttonText}>
                  Kontaktdaten bearbeiten
                </div>
              </Button>
            </div>
          )}

          <div
            style={mergeStyles([
              globalStyles.row,
              styles.alignCenter,
              styles.width100,
              isDesktop ? globalStyles.marginBottom35 : globalStyles.marginBottom10,
            ])}
          >
            <div
              className={classes['delete-account-text']}
              style={mergeStyles([
                globalStyles.column,
                styles.width100,
                isDesktop ? globalStyles.marginTop50 : globalStyles.marginTop50,
              ])}
            >
              <div className='text' style={styles.heading2}>
                Account löschen
              </div>
              <div className='text' style={styles.deleteDescription}>
                Löschen Sie Ihren Account sowie damit verbundene Daten aus allen Systemen und Diensten von nowomed.
              </div>
              <div className='text' style={styles.deleteDescription}>
                Beachten Sie bitte: Bei erfolgter Behandlung besteht für nowomed eine 10-jährige Aufbewahrungspflicht
                Ihrer medizinischen Dokumente.
              </div>
            </div>
          </div>

          <div
            className={classes['button-container']}
            style={mergeStyles([globalStyles.row, styles.deleteButtonContainer])}
          >
            <Button style={[styles.deleteButton, styles.deleteButtonSecondary]} onClick={() => setDeleteModal(true)}>
              <div style={styles.deleteButtonSecondaryText}>Account löschen</div>
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
